import { Link } from '@remix-run/react'

export function Footer() {
  return (
    <div className="footer mt-auto py-12">
      <aside className="order-last md:order-first">
        <div className="flex flex-row items-center space-x-2">
          <img
            src="https://eu-central-1-shared-euc1-02.graphassets.com/cluk77jyt0m4k07w108ezfwe1/resize=height:64/auto_image/clus8s6ex5aey08w1lxva3wgl"
            alt="Vatomat"
            className="h-12 grayscale"
          />
          <div>
            <h3 className="text-lg font-bold">Vatomat</h3>
            <div className="text-xs text-neutral-500">Víme, jak na vatu</div>
          </div>
        </div>
        <div className="text-sm text-neutral-500">
          Copyright &copy; 2024 - Všechna práva vyhrazena
        </div>
      </aside>
      <nav>
        <h6 className="footer-title">Produkt</h6>
        <Link
          to={`/obchodni-podminky-a-ochrana-osobnich-udaju`}
          className="link-hover link"
        >
          Obchodní podmínky
        </Link>
        <Link to={`/interest`} className="link-hover link">
          Zjistit výši dotace
        </Link>
      </nav>
      <nav>
        <h6 className="footer-title">Společnost</h6>
        <Link to={`/o-nas`} className="link-hover link">
          O nás
        </Link>
        <Link to={`/kontakt`} className="link-hover link">
          Kontakt
        </Link>
      </nav>
    </div>
  )
}
